import React from "react"
import { Box, Grid, makeStyles } from "@material-ui/core"

export const Divider = ({}) => {

  const useStyles = makeStyles((theme) => ({
    container: {
      [theme.breakpoints.down("md")]: {
        minHeight: "20vh",
        fontSize: "1.5em",
      },
      [theme.breakpoints.up("md")]: {
        minHeight: "20vh",
        fontSize: "2.5em",
      },
      backgroundColor: "#3c464a",
    },
    content: {
      color: "#f6f6f6",
      fontWeight: "800",
      textAlign: "center",
      textTransform: "uppercase",
      textShadow: "1px 0 1px #333333, 1px 0 1px #333333, 1px 0 1px #333333, 1px 0 1px #333333",
      letterSpacing: ".15em",
      padding: "1em"
    },
  }))
  const classes = useStyles()

  return (
    <Grid container alignItems={"center"} justify={"center"} className={classes.container}>
      <Grid item xs={12}>
        <Box className={classes.content}>{"como lo logramos?"}</Box>
      </Grid>
    </Grid>
  )
}
