import { StaticQuery } from "gatsby"
import React, { useRef } from "react"
import SEO from "../components/seo"
import { Box, Grid, makeStyles } from "@material-ui/core"
import Layout from "../components/common/layout"
import ContactSection from "../components/contact/contact-section"
import Steps from "../components/index/steps"
import { Divider } from "../components/index/divider"
import Campaign from "../images/steps/svg/campaign.inline.svg"
import ContactSectionSeparatorTop from "../images/dividers/contact-separator-top.inline.svg"

const IndexPage = () => {
  const useStyles = makeStyles((theme) => ({
    infoContainer: {
      color: "#2c2b3c",
      margin: 0,
      minHeight: "100vh",
      overflow: "hidden",
      [theme.breakpoints.down("md")]: {
        paddingTop: "2.5em",
        paddingBottom: "5em",
      },
    },
    infoTitle: {
      lineHeight: "1.15em",
      fontWeight: "600",
      fontSize: "2.25em",
      textTransform: "uppercase",
    },
    infoDescription: {
      fontSize: "1.25em",
      lineHeight: "1.75em",
      fontWeight: "300",
    },
    contactSection: {
      backgroundColor: "#f9f9f9",
      marginTop: "-1em",
      overflow: "hidden",
    },
  }))
  const classes = useStyles()

  const infoRef = useRef(null)
  const dividerRef = useRef(null)
  const stepsRef = useRef(null)
  const contactRef = useRef(null)

  // const listenScrollEvent = (event) => {
  //   if (window.scrollY < 70) {
  //     console.log(infoRef.current.getBoundingClientRect())
  //   }
  //     console.log("before " + infoRef.current.scrollHeight)
  //   } else {
  //     console.log("after " + infoRef.current.scrollHeight)
  //   }
  // }
  // window.addEventListener("scroll", listenScrollEvent)

  return (
    <StaticQuery
      render={data => (
        <>
          <SEO
            title={"Inicio"}
            description={data.site.siteMetadata.description}
            url={"https://www.waravix.com"}
          />

          <Layout currentPage={"home"} contactRef={contactRef} infoRef={infoRef}>

            {/*Presentation*/}
            <Grid container alignItems={"center"} justify={"center"} ref={infoRef} className={classes.infoContainer}>
              <Grid item xs={10} md={4}>
                <Campaign />
              </Grid>
              <Grid container direction={"vertical"} alignItems={"center"} justify={"center"} xs={10} md={4}>
                <Grid item>
                  <p className={classes.infoTitle}>
                    {"Proveemos un proceso de selección ágil, dinámico y especializado en tecnologías de software."}
                  </p>
                  <p className={classes.infoDescription}>
                    {
                      "Te ayudamos a encontrar a los mejores profesionales IT de acuerdo a las necesidades, cultura y valores de tu organización."
                    }
                  </p>
                </Grid>
              </Grid>
            </Grid>

            {/*Divider*/}
            <Divider refProp={dividerRef} />

            {/*Steps*/}
            <Steps refProp={stepsRef} />

            <ContactSectionSeparatorTop style={{ backgroundColor: "#f9f9f9" }} />

            {/*Contact*/}
            <Box ref={contactRef}>
              <Grid container className={classes.contactSection}>
                <ContactSection />
              </Grid>
            </Box>
          </Layout>
        </>
      )}
      query={graphql`
        query IndexQuery {
          site {
            siteMetadata {
              description
            }
          }
          presentationBackground: file(absolutePath: { regex: "/presentation/" }) {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          magnet: file(absolutePath: { regex: "/magnet/" }) {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
    />
  )
}

export default IndexPage
