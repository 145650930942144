import React from "react"
import { Grid, Hidden, makeStyles } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import Profiling from "../../images/steps/svg/profiling.inline.svg"
import Developer from "../../images/steps/svg/developer.inline.svg"
import TeamWork from "../../images/steps/svg/team-work.inline.svg"
import TeamMeeting from "../../images/steps/svg/team-meeting.inline.svg"

const Steps = ({ refProp }) => {
  const data = useStaticQuery(graphql`
    query StepsQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  const useStyles = makeStyles((theme) => ({
    stepsContainer: {
      color: "#2c2b3c",
      backgroundColor: "#EBF4F6",
      margin: 0,
      marginTop: "-1em",
      paddingTop: "3em",
    },
    stepContainer: {
      width: "100%",
      margin: 0,
      padding: "0",
      [theme.breakpoints.down("md")]: {
        minHeight: "25vh",
      },
      [theme.breakpoints.up("md")]: {
        minHeight: "45vh",
      },
    },
    stepImageContainer: {
      paddingTop: "0 !important",
      paddingBottom: "0 !important",
    },
    stepTitle: {
      [theme.breakpoints.down("md")]: {
        fontSize: "2em",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "2em",
      },
      fontWeight: "600",
      margin: ".5em 0",
      textTransform: "uppercase",
    },
    stepDescription: {
      fontSize: "1.25em",
      lineHeight: "1.5em",
      fontWeight: "300",
    },
    stepImage: {
      width: "100%",
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    noTopPadding: {
      [theme.breakpoints.down("md")]: {
        paddingTop: "0 !important",
      },
    },
  }))
  const classes = useStyles()

  const Step = ({ title, description }) => (
    <>
      <p className={classes.stepTitle}>{title}</p>
      <p className={classes.stepDescription}>{description}</p>
    </>
  )

  return (
    <Grid ref={refProp} container direction={"column"} justify={"center"} alignItems={"center"}
          className={classes.stepsContainer}>

      {/*"Trabajamos en equipo!"*/}
      <Grid container justify={"center"} alignItems={"center"} className={classes.stepContainer}>
        <Hidden mdUp>
          <Grid item xs={9} className={classes.stepImageContainer}>
            <TeamWork />
          </Grid>
        </Hidden>
        <Grid item xs={10} md={3} className={classes.noTopPadding}>
          <Step
            title={"Trabajamos en equipo!"}
            description={
              "Estamos convencidos de que el trato personalizado es el primer paso para construir juntos " +
              "el comienzo de una búsqueda efectiva."
            }
          />
        </Grid>
        <Hidden mdDown>
          <Grid item md={3}>
            <TeamWork />
          </Grid>
        </Hidden>
      </Grid>

      {/*"Proceso refinado"*/}
      <Grid container justify={"center"} alignItems={"center"} className={classes.stepContainer}>
        <Hidden mdDown>
          <Grid item md={3} className={classes.stepImageContainer}>
            <Profiling />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={9}>
            <Profiling />
          </Grid>
        </Hidden>
        <Grid item xs={10} md={3} className={classes.noTopPadding}>
          <Step
            title={"Proceso refinado"}
            description={
              "Adaptamos el proceso de acuerdo a tus necesidades. " +
              "Realizamos entrevistas técnicas, personales y de idiomas presentandote los mejores candidatos."} />
        </Grid>
      </Grid>

      {/*"Búsquedas especializadas"*/}
      <Grid container justify={"center"} alignItems={"center"}
            className={classes.stepContainer}>
        <Hidden mdUp>
          <Grid item xs={9} className={classes.stepImageContainer}>
            <Developer />
          </Grid>
        </Hidden>
        <Grid item xs={10} md={3} className={classes.noTopPadding}>
          <Step
            title={"Búsquedas especializadas"}
            description={
              "Iniciamos una búsqueda exhaustiva de talentos. Contamos con una extensa base de " +
              "datos y un sistema desarrollado a medida para brindar resultados de calidad en el menor tiempo " +
              "posible."
            }
          />
        </Grid>
        <Hidden mdDown>
          <Grid item md={3}>
            <Developer />
          </Grid>
        </Hidden>
      </Grid>

      {/*"Seguimiento e Ingreso"*/}
      <Grid container justify={"center"} alignItems={"center"} className={classes.stepContainer}>
        <Hidden mdUp>
          <Grid item xs={9} className={classes.stepImageContainer}>
            <TeamMeeting />
          </Grid>
        </Hidden>
        <Hidden mdDown>
          <Grid item md={3}>
            <TeamMeeting />
          </Grid>
        </Hidden>
        <Grid item xs={10} md={3} className={classes.noTopPadding}>
          <Step
            title={"Seguimiento e Ingreso"}
            description={"Mantenemos una comunicación fluida en todas las instancias, asesorándote y acompañándote durante todo el proceso de selección."}
          />
        </Grid>
      </Grid>

    </Grid>
  )
}

export default Steps